/* Container Styling */
.ipDetail {
  text-align: left;
  margin-top: 2%;
  color: #cfd8dc;
}

.titleBox {
  font-size: 1.2rem;
  color: #b0bec5;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 1px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
  margin-bottom: 25px;
}

.titleBox > p {
  margin: auto;
}

.location > p {
  font-size: 1.2rem;
  color: #b0bec5;
  margin: 20px;
}

.ipDetail.location {
  border-radius: 8px;
  padding: 20px;
  margin: 3%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  
  /* Force hardware acceleration */
}

.ipDetail.location:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 15px rgba(255, 255, 255, 0.1);
}


/* IP Display */
.ipDisplayContainer {
  display: flex;
  align-items: center;
  gap: 16px;

}

.ipContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15%;
  margin-top: 5%;

}

.ipDisplayed {
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.ipDisplayed:hover {
  transform: translateY(-3px);
}

.ipAddress {
  font-size: 3rem;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
}

/* Interactive Icon */
.material-symbols-outlined {
  color: #b0bec5;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.material-symbols-outlined:hover {
  color: #cfd8dc;
  transform: scale(1.1);
}

.material-symbols-outlined:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -1.9em;
  left: 50%;
  transform: translateX(-50%);
  color: #e0e0e0;
  padding: 0.5em;
  border-radius: 5px;
  font-size: 0.8rem;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
}

/* Buttons */
button {
  padding: 12px 24px;
  border: none;
  background: #333;
  color: #e0e0e0;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background: #444;
  transform: scale(1.05);
}

/* Spinner */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-top-color: #b0bec5;
  animation: spin 1s linear infinite;
  margin: auto;
  display: block;
  margin-bottom: 2%;
}


/* Ensure the container is positioned relative for absolute tooltip positioning */
.material-symbols-outlined {
  position: relative;
  cursor: pointer;
  /* Optional: ensure text is rendered crisply */
  -webkit-font-smoothing: antialiased;
}


/* Show tooltip on hover */
.material-symbols-outlined:hover::after,
.material-symbols-outlined:hover::before {
  opacity: 1;
  font-size: 0.8rem;
  font-family: 'Trebuchet MS', sans-serif;
}



@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .ipAddress {
    font-size: 1.4rem;
  }

  .ipDetail.location {
    width: 65%;
  }

  .hide-on-mobile {
    display: none;
  }
.renderedContent {
  width: 110%;
}

}

/* Light Mode Overrides */
body:not(.dark-mode) .ipDetail {
  color: #333;
}

body:not(.dark-mode) .titleBox {
  color: #333;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

body:not(.dark-mode) .ipDetail.location {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

body:not(.dark-mode) .ipAddress {
  color: #000;
  text-shadow: none;
}

body:not(.dark-mode) .material-symbols-outlined {
  color: #555;
}
