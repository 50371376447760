
.donate-container {
    width: 80%;
    max-width: 800px;
    /* Top margin set using clamp: never less than 100px, scales with viewport, but never more than 150px */
    margin-top: clamp(200px, 10vh, 150px); /* Minimum 80px, preferred 10vh, maximum 120px */
    padding: 20px;
    text-align: left;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color:beige;

  }
.stripePaymentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 5rem;
  }

  .donation-security {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8f5e9; /* Light, reassuring green */
    border: 1px solid #c8e6c9;
    color: #2e7d32; /* Darker green for contrast */
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px; /* Spacing above the donation form */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .security-icon {
    margin-right: 8px;
    font-size: 20px;
  }
  


/* Light Mode Adjustments */
body:not(.dark-mode) .donate-container {
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


  .titleText {
    font-size: 1.7rem;
    color: #b0bec5;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    border-radius: 1px;
    border-bottom: 3px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
    margin-bottom: 25px;
  }
  
