
  

  .sidebar-open .expandNavMenu {
    margin:auto;
    
}


/* Menu items inside sidebar */
.menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    height: 100%;
    width: 100%;
   font-family: monospace;
}

.menu-items > .nav-item:hover {
    cursor: pointer;
    color: #3aa9d4;
    font-weight: bold;
    text-shadow: 0 0 5px #0d2731;
    border-radius: 5px;
    width: 100%;
transition: all 0.3s ease-in-out;
}


  /* Sidebar Styling */
  .sidenav {
    position: fixed;
    left: 10px;
    top: 600px; /* Below header */
   text-align: left;
    width: 0px; /* Closed state */
    height: 33vh;
    background-color: #1b1a18;
    transition: width 0.4s ease-in-out;
    z-index: 1000;
    border-radius: 3px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    color: #E0E0E0;
    font-family: 'Handjet';
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    opacity: 0.9;
    z-index: 1000;
 
  }
  
  .sidenav.open {
    width: 150px; /* Expanded sidebar width */

  }

  .expandNavMenu {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    background-color: #3aa9d4;
    color: #fcfbf9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1100; /* Ensure it stays on top */
    transition: left 0.4s ease-in-out, opacity 0.3s ease-in-out;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.expandNavMenu:hover {
    background-color: #2a8db4;
    transform: scale(1.1);
}