/* Ensures full-page layout */
.App {
  display: flex;
  flex-direction: column;
  /* Remove align-items: center to allow full-width children */
  min-height: 100vh;
  background-color: #32312f;
}

.container {
  flex: 1;
  width: 100%;
}

/* Remove any default margin/padding from body */
body,
html {
  margin: 0;
  width: 100%;
}


.dark-mode-toggle {
  background: none;
  border: 1px solid white;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dark-mode-toggle:hover {
  background: beige;
  color: #575757;
  border: 1px solid #575757;
}

.darkModeButton-Container {
  display: flex;
  justify-content: flex-end;
  margin: auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 0 1rem; /* Adjust as needed */
  background-color: #0072C6;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  font-family: 'Handjet';
  z-index: 1000;
  width: 100%;
  color: white


}

.header-left {
  justify-self: start;
}

.header-center {
  justify-self: center;
  text-align: center;
}

.header-right {
  justify-self: end;
}

/* Header title remains the same */
.header h1 {
  margin: auto;
  font-size: 52px;
  font-weight: bold;
  cursor: pointer;
  color: #fcfbf9;
}


.specialHeader {
  font-weight: 200;
  font-size: 1.6rem;
  color: #dddcda;
  text-align: center;
  letter-spacing: 0.1rem;

}

.siteTitle {
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;

}

.siteTitle:hover {
  transform: scale(1.1);

}



.logo-container {
  /* inline-flex ensures the container only takes up as much space as its content */
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: auto;
  line-height: 0;
display: flex;
  flex-direction: row;

}

.logo {
  margin-left: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  object-fit: 'contain';
height: 3rem;
  width: 3rem;
  padding: 10px;
}

.logo:hover {
  transform: scale(1.1);
  color: #2a8db4;
}




/* Wrapper for Content */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: margin-left 0.4s ease-in-out;

}


.renderedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  flex-grow: 1;
  /* Add padding-top to push content below the 80px header plus extra space 
  padding-top: clamp(80px, 10vh, 100px);
  
  */
  padding-bottom: 50px;
  width: calc(100% - 80px);
  /* Adjust based on sidebar width */
  overflow-y: auto;
  /* Allows scrolling if content overflows */
  overflow-x: hidden;
  /* Prevents horizontal scrolling */
  padding-bottom: 60px;
  /* Adjust this value to match or exceed your footer's height */
}

.articleLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 10%;

}

.articleLink {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  /* Adjust based on sidebar width */
  overflow-y: auto;
  /* Allows scrolling if content overflows */
  overflow-x: hidden;
  /* Prevents horizontal scrolling */
  margin-top: -5%
}

.footer {
  width: 150%;
  text-align: center;
  font-size: 14px;
  color: #777;
  padding: 10px; /* Optional padding */
  justify-content: center;
display:flex;
  align-items: center;
  padding: 0 1rem; /* Adjust as needed */
  width: 100%;
}

.donateLink {
  cursor: pointer;

}

.donateLink:hover {
  color: #2a8db4;
}



.flatIco-Contribution {
font-size: 0.6rem;
text-align: right;
margin: auto;
}

/* FONT STYLING - Use a consistent font for the entire app */

.code-box {
  display: inline-block;
}

.code-text {
  margin: 0;
  /* Remove default margin */
  font-size: 0.85rem;
  /* Slightly smaller text for inline code */
  background-color: #eee;
  /* Light gray background */
  color: #555;
  /* Dark text color */
  border: 1px solid rgba(27, 31, 35, 0.15);
  /* Subtle border */
  border-radius: 3px;
  /* Rounded corners */
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  /* Monospaced font */
  white-space: pre;
  /* Preserve whitespace formatting */
  padding: 0.2em 0.4em;
  /* Comfortable padding */
}

.code-text-p {
  margin: 0;
  /* Remove default margin from <p> */
  font-size: 1rem;
  background-color: #2d2d2d;
  /* Dark background */
  color: #ccc;
  /* Light text */
  border: 1px solid #444;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre;
  padding-right: 0.3em;
  padding-left: 0.3em;
}


.titleText {
  font-size: 1.7rem;
  color: #b0bec5;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border-radius: 1px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
  margin-bottom: 25px;
}


.pageBreak {
  width: 100%;
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  margin: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;

}

.articleText-container {
  width: 80%;
  max-width: 800px;
  /* Top margin set using clamp: never less than 100px, scales with viewport, but never more than 150px 
  margin-top: clamp(200px, 10vh, 150px);
  
  */
  /* Minimum 80px, preferred 10vh, maximum 120px */
  margin-top: 5%;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: beige;
  font-family: "Lato", sans-serif;
}



/* -- COMPONENT STYLING -- */
.hoverEffect-Text {
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;

}

.hoverEffect-Text:hover {
  transform: scale(1.1);

}


tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

td {
  padding: 8px 12px;
  font-size: 0.95rem;
  text-align: left;
}

.ipDetail.location table {
  width: 100%;
  margin-right: 200px;
}

.ipDetail.location td {
  padding: 8px 12px;
  font-size: 0.95rem;
  text-align: left;
}

.ipDetail.location tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}


/* -- LIGHT MODE STYLING -- */

body:not(.dark-mode) tr td {
  border-bottom: 1px solid #555;
}

body:not(.dark-mode) .App {
  background-color: #eee;
  color: #555;
}

body:not(.dark-mode) .dark-mode-toggle:hover {
  background: #535250;
  color: white;
  border: 1px solid #575757;
}


body:not(.dark-mode) .articleText-container {
  background: beige;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: #222;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

body:not(.dark-mode) .code-text {
  background-color: #3f3e43;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: #eee;

  border: 1px solid rgba(0, 0, 0, 0.1);
}
/* Light gray background */

body:not(.dark-mode) .titleText {
  background: #0072c5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: beige;
  border: 1px solid rgba(0, 0, 0, 0.1);
}


body:not(.dark-mode) .footer {
  width: 150%;
  text-align: center;
  font-size: 14px;
  color: black;
  padding: 10px; /* Optional padding */
  justify-content: center;
display:flex;
  align-items: center;
  padding: 0 1rem; /* Adjust as needed */
  width: 100%;
}

/* Responsive */
@media (max-width: 768px) {
  .articleText-container {
    width: 90%;
    padding: 15px;
  }
}