
.about-container {
    width: 80%;
    max-width: 800px;
    /* Top margin set using clamp: never less than 100px, scales with viewport, but never more than 150px */
    margin-top: clamp(200px, 10vh, 150px); /* Minimum 80px, preferred 10vh, maximum 120px */
    padding: 20px;
    text-align: left;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color:beige;
  }
/* Light Mode Adjustments */
body:not(.dark-mode) .about-container {
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

